 var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
 var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
 var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

 // Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
 function stripPhpContents(str) {
     return str.replace(rex_php_tags, "");
 }

 // Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
 function stripJSContents(str) {
     return str.replace(rex_script_tags, "");
 }

 // Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
 function stripHTMLComments(str) {
     return str.replace(rex_comments_tags, "");
 }

 function stripCombo(str) {
     var output = stripHTMLComments(str);

     output = stripJSContents(output);
     output = stripPhpContents(output);

     return output;
 }

 var elmt;
 var loader;
 var prev_display;
 // Shows button's underlying loader and triggers defined action
 function waitingProcess(loader, action, id_form) {
     var elmt = $(this),
         loader = $(loader);

     prev_display = elmt.css("display");

     if (!loader.parent().hasClass("loading")) {
         loader.parent().addClass("loading");

         switch (action) {
             case 0:
                 window.setTimeout(
                     ajax_checkAllForm,
                     1000,
                     tabAuthTop,
                     1,
                     path_relative_root
                 );
                 break;

             case 1:
                 window.setTimeout(
                     ajax_checkAllForm,
                     1000,
                     tabLostPwdTop,
                     1,
                     path_relative_root
                 );
                 break;

             case 2:
                 window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                 break;

             case 3:
                 window.setTimeout(function() {
                     checkAllForm(tabError, 0, path_relative_root, id_form);
                     loader.parent().removeClass("loading");
                 }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                 break;

             case 4:
                 window.setTimeout(
                     ajax_checkAllForm,
                     1000,
                     tabAuth,
                     1,
                     path_relative_root
                 );
                 break;

             case 5:
                 window.setTimeout(
                     ajax_checkAllForm,
                     1000,
                     tabLostPwd,
                     1,
                     path_relative_root
                 );
                 break;

             case 6:
                 window.setTimeout(checkFormParrainage, 1000);
                 break;

             case 7:
                 if (
                     checkAllForm(
                         tabErrorGrattez,
                         0,
                         path_relative_root,
                         id_form
                     )
                 ) {
                     window.setTimeout(processGrattezGagnez(), 1000);
                 }
                 break;
         }
     }
 }

 function hideLoader() {
     if (loader) {
         loader.css("display", "none");
         loader
             .removeAttr("style")
             .parent()
             .removeClass("loading");
     }
     if (elmt) {
         elmt.css("display", prev_display);
     }
 }

 // Checks any form
 function ajax_checkAllForm(
   tabError,
   blockMenuTopConnexionInfobulle,
   path_relative_root
  ) {
   if (checkAllForm(tabError, blockMenuTopConnexionInfobulle)) {
       if (tabError[0][1] && tabError[0][1] == "clientMailAuthTop") {
           $.ajax({
               url: path_relative_root + "ajax_login_allowed.php",
               type: "get",
               data: {
                   mail: document.getElementById(tabError[0][1]).value,
                   mdp: document.getElementById(tabError[1][1]).value,
                   is_top: true,
               },
               success: function(res) {
                   var url, index_deb, index_fin, provenance;

                   if (res[0] !== "0") {
                       url = window.location.pathname;
                       index_deb = url.lastIndexOf("/");
                       index_fin = url.lastIndexOf(".");
                       provenance = url.substring(index_deb + 1, index_fin);

                       if (provenance === "connexion") {
                           window.location.replace(
                               path_relative_root + create_link("client_index")
                           );
                       } else if (provenance === "identification") {
                           window.location.replace(
                               path_relative_root + "livraison-kiala.php"
                           );
                       } else {
                           window.location.reload();
                       }
                   } else {
                       document.getElementById("AuthTopErr").style.display =
                           "block";

                       $(".loader").css("display", "none");
                   }
               },
           });
       } else if (tabError[0][1] && tabError[0][1] == "mailLostTop") {
           var mail = document.getElementById(tabError[0][1]).value;

           $.ajax({
               url: path_relative_root + "ajax_email_exist.php",
               type: "get",
               data: {mail: mail},
               success: function(res) {
                   if (res == 1) {
                       $.ajax({
                           url:
                               path_relative_root +
                               "ajax_send_new_password_link.php",
                           type: "get",
                           data: {mail: mail},
                           succes: function() {
                               show_lightbox(
                                   "popup_confirm_reinitialisation_mdp"
                               );

                               document.getElementById(
                                   "lostpwdTopErr"
                               ).style.display =
                                   "none";
                               document.getElementById(
                                   "lostpwdTopSuccesTitle"
                               ).style.visibility =
                                   "hidden";

                               hideLoader();
                           },
                       });
                   } else {
                       document.getElementById("lostpwdTopErr").style.display =
                           "block";

                       hideLoader();
                   }
               },
           });
       } else if (tabError[0][1] && tabError[0][1] == "clientMailAuth") {
           $.ajax({
               url: path_relative_root + "ajax_login_allowed.php",
               type: "get",
               data: {
                   mail: document.getElementById(tabError[0][1]).value,
                   mdp: document.getElementById(tabError[1][1]).value,
                   is_top: false,
               },
               success: function(res) {
                   hideLoader();

                   if (res == 1) {
                       $("#auth").submit();

                       return true;
                   } else {
                       document.getElementById("AuthErrErr").style.display =
                           "block";

                       return false;
                   }
               },
           });
       } else if (tabError[0][1] && tabError[0][1] == "mailLost") {
           var mail = document.getElementById(tabError[0][1]).value;

           $.ajax({
               url: path_relative_root + "ajax_email_exist.php",
               type: "get",
               data: {mail: mail},
               success: function(res) {
                   if (res == 1) {
                       $.ajax({
                           url:
                               path_relative_root +
                               "ajax_send_new_password_link.php",
                           type: "get",
                           data: {mail: mail},
                           success: function(res) {
                               show_lightbox(
                                   "popup_confirm_reinitialisation_mdp"
                               );

                               document.getElementById(
                                   "lostpwdErr"
                               ).style.display =
                                   "none";
                               document.getElementById(
                                   "lostpwdSuccesTitle"
                               ).style.visibility =
                                   "hidden";

                               hideLoader();

                               return false;
                           },
                       });
                   } else {
                       document.getElementById("lostpwdErr").style.display =
                           "block";

                       hideLoader();

                       return false;
                   }
               },
           });
       }
   } else {
       $(".loader").css("display", "none");
   }
}

 // Ensures this.value is non-empty
 // Binds to HTMLInputElement
 // Returns true if value is non-empy, false otherwise
 function nonEmpty() {
    return !!this.value.trim();
 }

 // Adds "display" rule to bond element's style with a value of "block"
 function show() {
    this.style.display = "block";
 }

 // Adds "display" rule to bond element's style with a value of "none"
 function hide() {
    this.style.display = "none";
 }

 // Adds leading zeroes to a given value
 function zeroPad(nbr, base) {
    var zpad;

    base = parseInt(base) > 1 ? base : 10;
    zpad = nbr < base ? "0" : "";

    return zpad + nbr.toString(base).toUpperCase();
 }

// // Alters item quantity in cart
 function changeQte(type) {

     var types = [ 'plus', 'less' ],
         item, loader, qtty, ajax_url;

     if (types.includes(type)) {

         item = this.getValue('item_id', parseFloat);
         loader = document.getElementById('qte_loader_' + item);
         qtty = this.getValue('selected_qte', parseFloat);

         this.qtty_select_wrap.style.display = 'none';
         loader.style.display = 'initial';

         switch (type) {
             case 'plus':
                 qtty += 1;
                 break;
             case 'less':
                 qtty -= 1;
                 break;
         }

         $.ajax({
             url: path_relative_root + create_link('order_basket'),
             type : 'post',
             data : {
                 action : 'del',
                 panierId : item,
                 cad  : 'non',
                 pointcad : 0,
                 qteProd : qtty,
                 change_qte : true
             },
             cache : false,
             success : function (res) {
                 try {
                     res = JSON.parse(res);
                 } catch (e) {
                     console.error('JSON ERROR: could not parse data');
                     return false;
                 }

                 res = $('<div />').html(res.datas);
                 var pushBanner = document.getElementsByClassName('push_container')[0];
                 document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html(); 
                
                 if(pushBanner){
                        $("#main_cart_wrapper>div").first().append(pushBanner);
                    }
                    
                 if (wShop.$refs.wCouponForm) {
                     wShop.$refs.wCouponForm.reloadBloc+=1;
                 }

                $('.product_quantity_change').each(function() {
                    var parentContainer = $(this).parent().parent();
                    $(this).appendTo(parentContainer.find('.cart_product_sizecol.quantity'));
            
                    setTimeout(function(){
                        $('.product_quantity_change').each(function() {
                            $(this).css('opacity', '1');
                        });
                    }, 300);
                });

                 //update top cart
                 var response = ajax_file("ajax_show_basket.php");
                 var addedTxt = '<span>' + translate('produit_precommande_text_mdp') + '</span>';

                 if(qtty < 2){
                    var prodinbask = '<span class="nb_products">(' + qtty + ' ' + translate('one_product') + ')</span>';
                 } else {
                    var prodinbask = '<span class="nb_products">(' + qtty + ' ' + translate('2_product') + ')</span>';
                 }

                 document.getElementById('cart_top').innerHTML = response;

                 $('.cart_main_title.basket').append(prodinbask);
                 $('.precoDeliveryDate').append(addedTxt);
             }
         });
     }
 }

 // Reload product from ref
 function loadProduct(new_id, type, page, itm_rr_id) {

     var form = this;
     var old_id = this.getValue('produit_id');
     var col_id = this.getValue(this.getValue('idCouleur'));
     var sze_id = this.getValue(this.getValue('idTaille'));
     var act = this.getValue('act');
     var qte_itm = 1;
     if(this.elements.namedItem('qteProd')  ) {
          var qte_itm = this.elements.namedItem('qteProd').value;
     }

     var is_model = this.getValue('hdn_is_model');
     var is_wishlist = $('#wishlist').length > 0;
     var is_achat_express = $('#is_achat_express_v2').val() == 1;
     var isCategSuper = this.getValue('isCategSuper') ? this.getValue('isCategSuper') : 0;

     // to avoid calling the view vertical_product.php in category_super/layouts when product's color is changed on search result page
     if ($('#search_page').length) {
        var isCategSuper = 0;
     }
     
     var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
     var data = {
          produit_id : old_id,
          produit_principal : old_id,
          couleurProd : col_id,
          tailleProd : sze_id,
          act : act,
          qteProd : qte_itm,
          hdn_product_id : old_id,
          hdn_is_model : is_model,
          produit_id_new : new_id,
          type : type,
          type_page : (page !== undefined && page !== '' ) ? page : 'product',
          is_achat_express : is_wishlist ? 1 : 0,
          is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
          cpt_prod : cpt_prod-1, 
          isCategSuper : isCategSuper
     };

     if (is_wishlist) {
          data.wishlist = true;
     }

     $.ajax({
          type : 'post',
          url : path_relative_root + 'ajax_get_product_regroup_ref.php',
          data : data,
          /*beforeSend : function () {

          },*/
          success : function (res) {

              try {

                  res = $.parseJSON(res);
              } catch (e) {

                  console.error('parseJSON');
                  return;
              }

              if (res.success == 'ok') {

                  var datas = res.datas;

                  if (data.type_page == 'product') {

                      var $html_main = $(stripCombo(datas.html_main));

                      var main_wrapper = $('.product_main_wrapper', $html_main);
                      var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                      var feats_wrapper = $('.product_feats_wrapper', $html_main);
                      var assoc_products = $('.product_associations_wrapper', $html_main);

                      if (data.is_achat_express_v2) {
                          // Photo et information
                          var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                          $('#itm-' + itm_id).replaceWith($html_main);

                          var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                          var margin_right = $('#itm-' + new_itm_id ).next().css('margin-right');

                          if ($(".homepage").length>0 || $(".satellite_store_details").length>0) {
                              save_description = $('#itm-' + new_itm_id + ' .wrapper_description').html();
                              $('#itm-' + new_itm_id + ' .wrapper_description').html('<div class="name">'+save_description+'</div>');
                              save_item_title = $('#itm-' + new_itm_id + ' .item_title').html();
                              $('#itm-' + new_itm_id + ' .item_title').html('<span>'+save_item_title+'</span>')
                              if($(".satellite_store_details").length>0) {
                                  $('#itm-' + new_itm_id ).addClass('swiper-slide');
                                  $('#itm-' + new_itm_id ).css('margin-right',margin_right);
                              }
                          } else if ($(".product_page").length>0 || $(".rayon_lookbook").length>0) {
                              if ($(".product_page").length>0) {
                              } else if($(".rayon_lookbook").length>0) {
                                  $('#itm-' + new_itm_id + ' .imgWrapper').addClass('imgWrapperinlook');
                              }
                          }
                          $('input.color_' + new_id + itm_rr_id).prop('checked', false);

                          $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                      } else if (is_wishlist) {
                          // Photo et information
                          $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                          // Description compo, livraison, guide tailles
                          $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());
                      } else {

                          var $html_middle = $(stripCombo(datas.html_middle));

                          var color = $("#ligne_couleur").html();

                          var taille = $("#ligne_pointure").html();

                          var contents = $('<div />').html($html_main);

                          // Photo et information
                          $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                          // Zoombox
                          $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());
                          // Description compo, livraison, guide tailles
                          $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                          $('.product_associations_wrapper').html(assoc_products.html());

                          var video = $('.wrapper_video', contents);

                          $("#ligne_couleur").html(color);
                          $('input.color').prop('checked', false);
                          $('input#color_' + data.couleurProd).prop('checked', true);

                          setTimeout(function(){
                              showAddBasketError();
                          }, 1000);

                          // On scroll en haut de la page dans le cas d'un regroupement
                          $('html, body').animate({
                              scrollTop: 0
                          }, 500);
                      }

                      if ($("#buyNowSwiper").length > 0) {
                          var buyNowSwiper = document.getElementById("buyNowSwiper");

                          if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {

                              buyNowSwiper = new Swiper("#buyNowSwiper", {
                                  sliderPerView: 1,
                                  spaceBetween: 50,
                                  nextButton: '.swiper-button-next',
                                  prevButton: '.swiper-button-prev'
                              });
                          }
                      }
                  } else if (type_page == 'look') {
                      var $html_main = $(stripCombo(datas.html_main));

                      var $html_middle = $(stripCombo(datas.html_middle));

                      var color = $("#ligne_couleur").html();

                      var main_wrapper = $('.product_main_wrapper', $html_main);

                      var feats_wrapper = $('.product_feats_wrapper', $html_main);

                      var contents = $('<div />').html($html_main);


                      // Photo et information
                      $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                      // Description compo, livraison, guide tailles
                      $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());


                      $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                      $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                      calculTotal('slide_look_detail');
                      $('#itm_thumb_list').empty();
                      var thumbnails = loadPrevisu();
                      parseThumbList(thumbnails);

                      var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                      var i = array_look.indexOf(parseInt($id_current));

                      if (i != -1) {

                          array_look.splice(i, 1);
                      }

                      // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                      showQteListeGamme('liste_qte_' + datas.product.id,  datas.product.id, path_relative_root);

                  }

                  json_colisages = res.stock;
                  // On definie les tailles qui ont encore du stock
                  if (!data.is_achat_express_v2) {
                      setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                  }

                  if (wShop.$refs.wAlertestockForm) {
                      wShop.$refs.wAlertestockForm.reloadBloc += 1;
                  }

              }else{
                  $( '#btn_error' ).fadeIn();
              }
          },
          complete : function () {

              var heads      = $("[data-tab-head]");
              var containers = $("[data-tab-container]");

              if (window.YT_Player !== undefined) {

                  window.YT_Player.destroy();

                  window.YT_Player = undefined;
                  window.YT_vid_id = undefined;
              }

              var activateTab = function(event) {
                  event.preventDefault();

                  var element        = $(this);
                  var tab_number     = element.attr("data-tab");
                  var head_container = element.parent();
                  var container_name, container, scrollpane;

                  head_container.addClass("current").siblings().removeClass("current");
                  container_name = head_container.parent().attr("data-tab-head");
                  container      = containers.filter("[data-tab-container='" + container_name + "']");
                  scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                  if (scrollpane.length > 0) {
                      scrollpane.jScrollPane();
                  }
              };

              setTimeout(function () {


                  if($("#visual_scroller").length <= 3) {
                    var productThumbs = new Swiper('#visual_scroller', {
                        slidesPerView: 'auto',
                        spaceBetween: 22,
                        direction: 'vertical',
                        freeMode: true,
                        loop:false,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                        navigation: {
                            nextEl: '.thumbNext',
                            prevEl: '.thumbPrev',
                        },
                        breakpoints: {
                            1160: {
                                direction: 'horizontal',
                                slidesPerView: 4,
                                spaceBetween: 15,
                                loop:false,
                            },
                        },
                    });
                }

                if($("#visual_scroller").length > 3) {

                    var productThumbs = new Swiper('#visual_scroller', {
                        slidesPerView: 'auto',
                        spaceBetween: 22,
                        direction: 'vertical',
                        freeMode: true,
                        loop:true,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                        navigation: {
                            nextEl: '.thumbNext',
                            prevEl: '.thumbPrev',
                        },
                        breakpoints: {
                            1160: {
                                direction: 'horizontal',
                                slidesPerView: 4,
                                spaceBetween: 15,
                                loop:false,
                            },
                        },
                    });
                }

                  if($("#visual_scroller .swiper-slide").length < 4) {
                      $('.more_vis').addClass('few_pics');
                  }

                  if($(".product_page .main_vis_fp_swiper .swiper-slide").length <= 3) {
                    var main_productVisu = new Swiper('.product_page .main_vis_fp_swiper', {
                        direction: 'vertical',
                        slidesPerView: 1,
                        mousewheel: true,
                        loop: false,
                        navigation: {
                            nextEl: '.thumbNext',
                            prevEl: '.thumbPrev',
                        },
                        thumbs: {
                            swiper: productThumbs
                        },

                        breakpoints: {
                            1160: {
                                direction: 'horizontal',
                            },
                        },
                        preloadImages: false,
                        lazy: true,
                        loadPrevNext: true,
                        loadOnTransitionStart: true


                    });
                }

                if($(".product_page .main_vis_fp_swiper .swiper-slide").length > 3) {
                    var main_productVisu = new Swiper('.product_page .main_vis_fp_swiper', {
                        direction: 'vertical',
                        slidesPerView: 1,
                        mousewheel: true,
                        loop: true,
                        navigation: {
                            nextEl: '.thumbNext',
                            prevEl: '.thumbPrev',
                        },
                        thumbs: {
                            swiper: productThumbs
                        },

                        breakpoints: {
                            1160: {
                                direction: 'horizontal',
                            },
                        },
                        preloadImages: false,
                        lazy: true,
                        loadPrevNext: true,
                        loadOnTransitionStart: true
                    });
                }


                if($("#thumbs_scroller").length > 0) {
                    var zoomThumbs = new Swiper('#thumbs_scroller', {
                        slidesPerView: 'auto',
                        spaceBetween: 14,
                        direction: 'vertical',
                        mousewheel: true,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                    });

                    $("#thumbs_scroller .swiper-slide img").on('click touch', function(){
                        //Update thumbs class
                        $("#thumbs_scroller .swiper-slide").each(function (index) {
                            $(this).removeClass('active_slide');
                        });

                        $(this).parent().addClass('active_slide');

                        //Update large image
                        var src = $(this).attr('src');
                        src = src.replace("medium", "large");
                        src = src.replace("mini", "large");
                        $('img.img_zoom').attr('src', src);
                    });
                }


                if($('#jqzoomSwiper .swiper-slide').length) {

                    var jqzoomSwiper = new Swiper("#jqzoomSwiper", {
                        slidesPerView: 1,
                        nextButton: '.swiper-button-next',
                        prevButton: '.swiper-button-prev'
                    });
                }


                if($("#zoom_box_wrapper .swiper-slide").length > 0) {
                    var main_productVisu = new Swiper('#zoom_box_wrapper', {
                        effect: 'fade',
                        slidesPerView: 1,
                        mousewheel: true,
                        navigation: {
                            nextEl: '.thumbsNext',
                            prevEl: '.thumbsPrev',
                        },
                        thumbs: {
                            swiper: zoomThumbs
                        },
                        preloadImages: true,
                        lazy: true,
                        loadPrevNext: true,
                        loadOnTransitionStart: true
                    
                    });

                }

                function updateSrc(path_web, dest) {
                    var tmp_string_large = document.getElementById("img_large").src;
                    tmp_string = tmp_string_large.replace(/medium/, "large");

                    document
                        .getElementById("img_large")
                        .setAttribute(
                            "onclick",
                            "zoomImg('" + tmp_string + "','" + path_web + "')"
                        );
                    document.getElementById("loupe").href =
                        "javascript:zoomImg('" + tmp_string + "','" + path_web + "');";
                    $(".zoomLens").css({backgroundImage: 'url("' + tmp_string_large + '")'});

                    if (dest == "vue") {
                        var i = 0;
                        var trouve = false;
                        while (i < tab_js.length && !trouve) {
                            trouve =
                                tab_js[i][0].substring(tab_js[i][0].lastIndexOf("/") + 1) ==
                                tmp_string.substring(tmp_string.lastIndexOf("/") + 1);
                            i++;
                        }

                        cpt_img = i - 1;
                    }
                }

                function updateZoomedImg(src) {
                    $(".main_vis img", this).attr("src", src);
                }

                /* Fin reinitialisation sliders visus */

                var more_vis = $('.more_vis');
                var grabber;

                more_vis.each(function () {

                    var jqzoom = $(this).next('.product_page .main_vis_fp_swiper');

                    if (jqzoom.length > 0) {

                        $(this).height(jqzoom.height());

                        if (document.getElementById('visual_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('visual_scroller'), true, false);
                            }, 250);
                        }
                    }

                    var zoom_box_wrapper = $(this).next('#zoom_box_wrapper');

                    if (zoom_box_wrapper.length > 0) {

                        $(this).height(zoom_box_wrapper.height());

                        if (document.getElementById('thumbs_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('thumbs_scroller'), true, false);
                            }, 250);
                        }
                    }

                });

                // Resetting alert stock field
                $('.bloc_add_alert_form').hide();

                var productImgsWrapper = $(".productImgsWrapper");
                var productDescrWrapper = $(".productDescrWrapper");

                $(document).on("scroll", function () {

                    var scrollTop = $(document).scrollTop();
                    var newTop = (scrollTop - (productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()));

                    if(scrollTop >= productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()) {

                        productDescrWrapper.css("top", "-" + newTop + "px");
                    } else {

                        productDescrWrapper.css("top", "auto");
                    }
                });

                $(window).on('resize', function () {

                    more_vis.each(function () {

                        var jqzoom = $(this).next('.product_page .main_vis_fp_swiper');

                        if (jqzoom.length > 0) {

                            $(this).height(jqzoom.height());
                        }

                        var jqzoom = $(this).next('#zoom_box_wrapper');

                        if (jqzoom.length > 0) {

                            $(this).height(zoom_box_wrapper.height());
                        }
                    
                    });
                });

                if ($(".pushProductHomeWrapper .item.swiper-slide").length > 1) {
                pushProductHomeWrapper = new Swiper(".pushProductHomeWrapper", {
                    slidesPerView: 1,
                    loop: false,
                    navigation: {
                            nextEl: '.swiper-pushProduct-button-next',
                            prevEl: '.swiper-pushProduct-button-prev',
                    },
                    pagination: {
                        el: '.swiper-pagination_2',
                        clickable: true,
                        type: 'fraction',
                        renderFraction: function (currentClass, totalClass) {
                        return '<span class="' + currentClass + '"></span>' + ' <span>-</span> ' + '<span class="' + totalClass + '"></span>'; }
                    },
                });

                var productSwiper = new Swiper(".productSwiper.swiper-container", {
                    slidesPerView: 1,
                    observer: true,
                    observeParents: true,
                    navigation: {
                        nextEl: '.rollover_rayonsNext',
                        prevEl: '.rollover_rayonsPrev',
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });

                }

                if ($(".pushProductHomeWrapper .item.swiper-slide").length >= 2) {
                    $('.button_and_pagination').show();
                }



                /***********
                FP Color
                ***********/
                $(document).ready(function() {
                    if($('body.category #site_global_wrap .rayon_lookbook').length) {
                        new SimpleBar($('.category .choices_list.choice_color')[0]);
                    }

                    $('#ligne_couleur').mouseover(function(e) {
                    e.preventDefault();
                        $('#ligne_couleur .js-dropp-action').addClass('drop_button');
                        $('.choices_list.choice_color').addClass('js-visible');
                        $('#ligne_couleur .drop_btn_big:not(".no_dropdown")').addClass('opened');
                        $('.choices_list.size').removeClass('js-visible');
                        $('#ligne_pointure .js-dropp-action').removeClass('drop_button');
                        $('#ligne_couleur').removeClass('closed');
                    });

                    $('#ligne_couleur').mouseout(function(){
                        $('#ligne_couleur .js-dropp-action').removeClass('drop_button');
                        $('.choices_list.choice_color').removeClass('js-visible');
                        $('#ligne_couleur').addClass('closed');

                        window.setTimeout(function() {
                            $('#ligne_couleur.closed .simplebar-content-wrapper').animate({ scrollTop: 0 }, "fast");
                        }, 1500);
                    });

                    // Using as fake input select dropdown
                    $('#ligne_couleur input').click(function() {
                        $(this).addClass('js-visible').siblings().removeClass('js-visible');
                        $('.choices_list.color,#ligne_couleur .js-dropp-action').removeClass('js-visible');
                        $('#ligne_couleur .drop_btn_big').removeClass('opened');
                        $(this).parent().addClass('active').siblings().removeClass('active');
                    });

                    $('.choices_list.choice_color .input_check').click(function() {
                        $('#ligne_couleur .dropp-header__btn').removeClass('drop_button');
                        $('.choices_list.choice_color.js-visible').removeClass('js-visible');
                        $('#ligne_couleur .drop_btn_big').removeClass('opened');
                        $('.choices_list.choice_color').addClass('color_selected');
                    });

                    $('#ligne_couleur .dropp-header__btn').click(function() {
                        $('.choices_list.choice_color.color_selected').removeClass('color_selected');
                    });

                    $('#ligne_couleur .prod_listes .choices_list .form_itm.color input:checked').parent('.form_itm').addClass('active');

                    window.setTimeout(function() {
                        $('#ligne_couleur .dropp .choices_list .form_itm.color').css('opacity','1');
                    }, 500);

                });


                setTimeout(function () {
                        checkSubmitState();
                        eventButton();
                    }, 500);
                }, 250);

              if($(".productSwiper.swiper-container").length && $('body').hasClass('product_page')) {
                var productSwiper_fp_hp = new Swiper(".productSwiper.swiper-container", {
                    slidesPerView: 1,
                    observer: true,
                    observeParents: true,
                    navigation: {
                      nextEl: '.pas-button-next',
                      prevEl: '.pas-button-prev',
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });
              }
              setTimeout(function () {
                $(".product_page .productSwiper .swiper-slide-off").css('opacity','1');
              }, 1500);

            window.setTimeout(function() {
                var w = $(window).width();
                if(w < 1300) {
                     $('#tete_gondole').addClass('small_swiper');
                     
                    if($("#productAssociationSwiper .swiper-slide.item").length > 2) {
                        var productAssociationSwiper = new Swiper('#productAssociationSwiper', {
                             slidesPerView: 2,
                             spaceBetween: 40,
                             loop: false,
                             navigation: {
                                nextEl: '.btn_nxt',
                                prevEl: '.btn_prv',
                            },
                        });
                       
                    } else {
                        $('#productAssociationSwiper').addClass('no_swiper');
                    }
                }
                else {
                    if($("#productAssociationSwiper .swiper-slide.item").length >= 4) {
                        var productAssociationSwiper = new Swiper('#productAssociationSwiper', {
                             slidesPerView: 4,
                             spaceBetween: 55,
                             allowTouchMove: false,
                             loop: false,
                             navigation: {
                                nextEl: '.btn_nxt',
                                prevEl: '.btn_prv',
                            },
                            breakpoints: {
                                1360: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1160: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                            },
                        });
                        
                    } else if($("#productAssociationSwiper .swiper-slide.item").length == 3) {

                        $('#productAssociationSwiper').css('max-width', '1260px');

                        var productAssociationSwiper2 = new Swiper('#productAssociationSwiper', {
                             slidesPerView: 3,
                             spaceBetween: 55,
                             allowTouchMove: false,
                             loop: false,
                             navigation: {
                                nextEl: '.btn_nxt',
                                prevEl: '.btn_prv',
                            },
                            breakpoints: {
                                1360: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1160: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                            },
                        });


                        
                    } else {
                        $('#productAssociationSwiper').addClass('no_swiper');
                    }

                    $('#tete_gondole').removeClass('small_swiper');
                }

                if($(".productSwiper.swiper-container").length) {
                  var productSwiper = new Swiper(".productSwiper.swiper-container", {
                      slidesPerView: 1,
                      observer: true,
                      observeParents: true,
                      navigation: {
                          nextEl: '.rollover_rayonsNext',
                          prevEl: '.rollover_rayonsPrev',
                      },
                      preloadImages: false,
                      lazy: true,
                      loadPrevNext: true,
                      loadOnTransitionStart: true
                  }); 
              }
            
            }, 500);

            $('#bloc_add_alert #btn_add_alert').click(function(){
              $(this).hide();
            });

            $('.bloc_add_alert_confirmation button').click(function(){
              $('#bloc_add_alert #btn_add_alert').show();
            });

            window.setTimeout(function() {
                  var swiper_home_main_push = $("#productAssociationSwiper .productBigPictures .wrap_video").width();
                  $("#productAssociationSwiper .productBigPictures .wrap_video iframe").height(swiper_home_main_push * '1.285');
             }, 1500);


            window.setTimeout(function() {
              var swiper_width_bug = $('#productAssociationSwiper .content-wrapper .item.swiper-slide[data-swiper-slide-index]').width();
              $('#productAssociationSwiper .content-wrapper .item.swiper-slide[data-pi]').css('max-width', swiper_width_bug);
            }, 5000);

              heads.each(function(){
                  var head = $(this);
                  var children = head.children();
                  children.removeClass("current").filter(":first").addClass("current");
                  children.find('[data-tab]').click(activateTab);
              });

              $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

              var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
              var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

              getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist);

              $('#addToWishlistButton', '#site_global_wrap').on('click', addToWishlist);

              if (!data.is_achat_express_v2) {
                  checkProductInWishlist(data.produit_id_new);
              }

              if (is_wishlist) {
                  // event sur le bouton d'ajout à la wishlist
                  $("#addToWishlistButton").on("click", addToWishlist);
              }

              $(document).ready(function video_responsive_r() {
                  window.setTimeout(function() {
                      $(window).on('load resize', function () {

                          var video_max_height = $('.home-module.slider-module #productAssociationSwiper .productSwiper').height();
                          $('.home-module.slider-module #productAssociationSwiper .wrap_video iframe').css('height', video_max_height);
                      
                      }).trigger('load resize');
                  }, 2000);
              });

              //Simulates scroll (2px) to load images
              window.scrollTo(0, $(window).scrollTop()+2);
          }
     });
 }

 // Called upon color change in product page
 function onItemColorChange(form, is_regroup, itm_rr_id) {

  
     var control, prod_id;
     itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
     var is_wishlist = $('#wishlist').length > 0;
     var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

     if (this.checked) {

         control = form.elements.namedItem(form.getValue('idCouleur'));
         control.value = this.value.trim();
         prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();

         if (is_regroup) {

             loadProduct.call(form, prod_id, 'couleur' , '', itm_rr_id);

             if ($('body.category').length) {
                 setTimeout(function() {
                     if ($('.choices_list.choice_color').length) {
                         new SimpleBar($('.category .choices_list.choice_color')[0]);
                     }
                 }, 1000);

                 showAddBasketError();
             }   
         } else {

            // On regarde si le produit est dejà dans la wishlist
            if (!is_achat_express_v2) {
                checkProductInWishlist();
            }

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
         }
     }
 }

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var control;
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {

        if ($('#sizeerror').is(':visible')) {

            $('#sizeerror').slideUp();
        }

        var id= form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id + itm_rr_id : '';

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();

        // En fonction de la couleur on regarde si le produit est disponible
        if (form.getValue('produit_idBookinstore') == undefined) { 
            getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), 'product', event, itm_rr_id, is_wishlist);
        }
        // On regarde si le produti est dejà dans la wishlist
        if (!is_achat_express_v2) {
            checkProductInWishlist();
        }
     }
 }

// Refreshes available quantity
 function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist) {
  itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
  var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
  var elem = is_achat_express ? '_' + id + itm_rr_id : '';

  var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
  var color_id = $('#couleurProd' + elem).val();
  var size_id = $('#tailleProd' + elem).val();

  // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
  if (color_id == '' || size_id == '') {
      return;
  }

  var data = {
      idprod: form.getValue('produit_id'),
      idcouleur: color_id,
      idtaille: size_id,
      json: '1'
  };

  if (from == 'basket') {
      data.suff = form.getValue('produit_id');
  }

  $.ajax({
          url: path_relative_root + 'ajax_liste_qte.php',
      type: 'get',
      async: false, // Should be async but would then need a loader so...
      dataType: 'json',
      data: data,
      success: function (res) {

          if (res.stock_tampon == 0) {

              $(".eclat_product_nouveaute_fiche_produit").show();
              $(".bloc_add_alert" + elem).show();
              $("#prod_info" + elem + " .rollover_left .productSizeFieldset").hide();
              $("#prod_info" + elem + " .bloc_add_color").hide();
              $("#qteProd").val(0);

              if (!is_achat_express) {
                  $("#bloc_add_basket").hide();
              }

              $('#ligne_qte').append($('.restock_date'));
              $('.taunt_text').css('display', 'none');
          } else {
              if (res.stock_tampon > 0 && res.stock_tampon <= 3) {
                  $('.taunt_text').css('display', 'block');
              } else {
                  $('.taunt_text').css('display', 'none');
              }

              if (!is_achat_express) {
                  $(".eclat_product_nouveaute_fiche_produit").hide();
                  $(".bloc_add_alert").hide();
                  $("#bloc_add_basket").show();
                  $("#qteProd").val(1);
                  $("#bloc_reserve_instore").show()
              }
          };
      }
  });
 }

 function waitingAddToBasket_legacy(
     produit_id,
     suff,
     panier_id,
     titreObjet,
     idTaille,
     lastTaille,
     idCouleur,
     lastCouleur,
     toEval,
     page_name,
     path_web,
     enable_omniture,
     is_not_produit,
     is_lightbox_specifique
 ) {
     var id = "";
     if (is_not_produit == true) id += "_" + produit_id;

     if (is_lightbox_specifique)
         add_produit_specifique(produit_id, idCouleur, idTaille);
     else
         window.setTimeout(
             'addToBasket_legacy("' +
                 produit_id +
                 '", "' +
                 suff +
                 '", "' +
                 panier_id +
                 '", "' +
                 titreObjet +
                 '", "' +
                 idTaille +
                 '", "' +
                 lastTaille +
                 '", "' +
                 idCouleur +
                 '", "' +
                 lastCouleur +
                 '","' +
                 toEval +
                 '", "' +
                 page_name +
                 '", "' +
                 path_web +
                 '", ' +
                 enable_omniture +
                 ", " +
                 is_not_produit +
                 ");",
             1000
         );

     $("#bloc_btn_active" + id).attr("class", "cache");
     $("#bloc_btn_loader" + id).attr("class", "f_right");
 }

 function addToBasket_legacy(
     produit_id,
     suff,
     panier_id,
     titreObjet,
     idTaille,
     lastTaille,
     idCouleur,
     lastCouleur,
     toEval,
     page_name,
     path_web,
     enable_omniture,
     is_not_produit
 ) {
     var id = "";

     if (is_not_produit == true) id += "_" + produit_id;

     prix_id = "prixU";
     if (is_not_produit == true) {
         var code_color = $("#couleurUnique_" + produit_id).text();
         var nom_color = $("#couleurUnique_" + produit_id).text();
         var prix_id = "prixU_" + produit_id;
     }
     var array_panier = newUpdatePanier_legacy(
         produit_id,
         suff,
         panier_id,
         document.getElementById(prix_id).value,
         path_web,
         titreObjet,
         idTaille,
         lastTaille,
         idCouleur,
         lastCouleur
     );

     if (array_panier != false) {
         var type = "";

         if (array_panier[1] == "false" || array_panier.length == 0) {
             type = "err";
         }

         omnitureEvent = "";
         var response = ajax_file(path_web + "ajax_show_basket.php");
         $("#cart_top").html(response);

         if (array_panier[1] === 0) {
             $("#nb_article").html(array_panier[1]);
             omnitureEvent = "";
         } else if (array_panier[1] == 1) {
             $("#nb_article").html(array_panier[1]);
             $("#panier")
                 .attr("href", path_web + create_link("order_basket"))
                 .attr("style", "cursor:pointer;");

             omnitureEvent = "scOpen,scAdd"; // le premier produit ajout� au panier
         } else {
             $("#nb_article").html(array_panier[1]);
             $("#panier")
                 .attr("href", path_web + create_link("order_basket"))
                 .attr("style", "cursor:pointer;");

             omnitureEvent = "scAdd"; // il y a d�j� un produit au panier
         }

         newshowmodal(
             array_panier[1],
             type,
             path_web,
             is_not_produit,
             produit_id
         );

         $("#bloc_btn_active" + id).attr("class", "");
         $("#bloc_btn_loader" + id).attr("class", "f_right cache");

         if (array_panier[1] > 0 && enable_omniture == true) {
             void s.t();
             s.pageName = "'" + page_name + "'";
             s.products = ";" + produit_id;
             s.events = omnitureEvent;
             void s.t();
         }
     } else {
         $("#bloc_btn_active" + id).attr("class", "");
         $("#bloc_btn_loader" + id).attr("class", "f_right cache");
     }

     eval(toEval);

     if (array_panier != false) {
         if (
             navigator.userAgent.indexOf("MSIE") != -1 &&
             navigator.userAgent.indexOf("6.") != -1
         ) {
             var svn = document.getElementsByTagName("SELECT");
             for (a = 0; a < svn.length; a++) {
                 svn[a].style.visibility = "hidden";
             }
         }
     }

     return false;
 }

 function newUpdatePanier_legacy(
  idProduit,
  i,
  paniervirtuelId,
  prixU,
  path_web,
  titreObjet,
  idElTaille,
  lastTaille,
  idElCouleur,
  lastCouleur
 ) {
  if (i != "") {
      i = "_" + i;

      prixU = $("#prixU" + i).val();
  }

  var couleurs = document.getElementById("couleurProd" + i);
  var tailles = document.getElementById("tailleProd" + i);
  var $alertbox = $("#alertbox1");
  var res = "";
  var html_err = "";
  var flagError = false;
  if ($("#lightbox_achat_express").length > 0) {
      from_range = true;
  } else {
      from_range = false;
  }

  html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
  html_err +=
      '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
  if ($alertbox.length == 0) {
      $alertbox = $('<div id="alertbox1">' + html_err + "</div>");
      $("body").append($alertbox.hide());
  }

  if (tailles && tailles.value == "") {
      res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_size");
      flagError = true;
  } else if (couleurs && couleurs.value == "") {
      res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_couleur");
      flagError = true;
  } else if (
      document.getElementById("qteProd" + i) &&
      document.getElementById("qteProd" + i).value == ""
  ) {
      res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_quantity");
      flagError = true;
  }

  if (flagError) {
      $alertbox.find(".txt_alert").html(res);
      openMultiShad("alertbox1");
      return false;
  } else {
      var qte = 0;

      if ($("#qteProd" + i)) {
          qte = $("#qteProd" + i).val();
      }

      var idCouleur = 0;
      var idTaille = 0;

      if (couleurs && couleurs.value != "none") idCouleur = couleurs.value;
      if (tailles && tailles.value != "none") idTaille = tailles.value;

      var array_response = new Array();
      var response = ajax_file(
          path_web +
              "ajax_upd_panier.php?prixu=" +
              prixU +
              "&idprod=" +
              idProduit +
              "&idcouleur=" +
              idCouleur +
              "&idtaille=" +
              idTaille +
              "&qte=" +
              qte +
              "&panierId=" +
              paniervirtuelId
      );

      if (response) {
          array_response = response.split("//");

          var designation = array_response[0];

          if (titreObjet.match("^[0-9]")) {
              if (designation == 1) {
                  designation += " lot de";
              } else {
                  designation += " lots de";
              }
          }

          designation += " " + titreObjet;

          if (document.getElementById(idElTaille)) {
              if (document.getElementById(idElTaille).selectedIndex) {
                  designation +=
                      " - Taille " +
                      document.getElementById(idElTaille).options[
                          document.getElementById(idElTaille).selectedIndex
                      ].text;
              } else if (lastTaille != "") {
                  designation += " - Taille " + lastTaille;
              }
          }

          if (document.getElementById(idElCouleur)) {
              if (document.getElementById(idElCouleur).selectedIndex) {
                  designation +=
                      " - " +
                      document.getElementById(idElCouleur).options[
                          document.getElementById(idElCouleur).selectedIndex
                      ].text;
              } else if (lastCouleur != "") {
                  designation += " - " + lastCouleur;
              }
          }

          array_response[0] = designation;
      }

      return array_response;
  }
 }

 $(document).on("click", ".thumblnk", function(evt) {
     var id_pannel = this.getAttribute("data-pannel");
     var thumbnav = $(this).closest(".thumbnav");
     var thumbcnt = thumbnav.next(".thumbcnt");

     evt.preventDefault();

     thumbnav
         .find(".thumblnk")
         .not(".thumblnk[data-pannel=" + id_pannel + "]")
         .removeClass("current");
     thumbcnt
         .find(".thumbslide")
         .not(".thumbslide[data-pannel=" + id_pannel + "]")
         .removeClass("current");

     thumbnav
         .find(".thumblnk[data-pannel=" + id_pannel + "]")
         .addClass("current");
     thumbcnt
         .find(".thumbslide[data-pannel=" + id_pannel + "]")
         .addClass("current");
 });

 // INSCRIPTION NEWSLETTER
 // La gestion de l'inscription à la newsletter
 $("input#newsletter_home").click(function() {
     $.ajax({
         url: path_relative_root + "ajax_newsletter_email.php",
         type: "post",
         data: {
             email: $("#newsletter_email").val(),
             provenance: "footer",
             sous_provenance: $("#newsletter_email").attr("data-lang"),
         },
         cache: false,
         success: function(res) {
             var resultat = JSON.parse(res);
             if (resultat.valid == false) {
                 $("#newsletter_email").addClass("inputErr");
             } else {
                 $("#error_newsletter").removeClass("error");
                 $("#error_newsletter").addClass("valid");
                 $("#newsletter_email").removeClass("inputErr");
             }
             $("#error_newsletter").html(translate(resultat.message));
         },
     });
 });

 function closeAlerteStock(elem) {

     // Hide bloc alert stock
     $('.bloc_add_alert_' + elem).hide();
     $("#prod_info_" + elem + " .rollover_left").show();
     $("#prod_info_" + elem + " .bloc_add_color").show();

     // Show bloc colors and size
     $('#ligne_pointure_' + elem).show();
     $('#ligne_couleur_' + elem).show();

     // Reset mail input
     $('.bloc_add_alert_' + elem + ' .alert_stock').show();
     $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
     $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
 }


 /**
  *
  * @param product_id : l'id du produit
  * @param regroup_ref_id : l'id du regroupement (si exist)
  * use in : app/views/_components/product/achat_express.php
  */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);

    // Loader
    btn.parent('.form_submit').addClass('loading');

    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id: '_' + product_id;
    var form = document.getElementById('prod_info' + elem);

    var color_id = form.elements['itm_color'].value;
    var size_id = form.elements['itm_size'].value;
    var tailles = $("#tailleProd" + elem);

    $("#ligne_pointure_error"+elem).remove();

    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    if ($('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) {
        $(this).parent().hide();
        $('#bloc_add_alert'+elem).show();
    } else { // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
        // On regarde si la personne a sélectionenr la taille et la couleur
        if (color_id !== '' && size_id !== '') {
            if (tailles.length && tailles.val() == "") {
                var txt_error = '<div class="productErrorFieldset" id="ligne_pointure_error'+elem+'">'+translate('quick_purchase_choose_a_size')+'</div>';
                btn.parent('.form_submit').removeClass('loading');
                $(txt_error).insertBefore($("#size_list"+elem));
            }
            waitingAddToBasket(event, form, '', regroup_ref_id);
            setTimeout(function() {
                btn.parent('.form_submit').removeClass('loading');
            }, 2000);
        } else {

            if (color_id === '' && size_id === '') {
                var txt_error = '<div class="productErrorFieldset" id="ligne_pointure_error'+elem+'">'+translate('quick_purchase_choose_a_size_and_a_color')+'</div>';
            } else if (color_id === '') {
                var txt_error = '<div class="productErrorFieldset" id="ligne_pointure_error'+elem+'">'+translate('quick_purchase_choose_a_color')+'</div>';
            } else if (size_id === '') {
                var txt_error = '<div class="productErrorFieldset" id="ligne_pointure_error'+elem+'">'+translate('quick_purchase_choose_a_size')+'</div>';
            }
            // Loader
            btn.parent('.form_submit').removeClass('loading');
            $(txt_error).insertBefore($("#size_list"+elem));  
        }
    }
}

 // YOUTUBE LOGIC
 var YT_Player, YT_vid_id;

 function onYouTubeIframeAPIReady() {

     YT_Player = new YT.Player('popup_video', {
         videoId : YT_vid_id,
         playerVars : {
             rel : 0
         }
     });

     if ($('#videoBox').is(':visible')) {

         YT_Player.playVideo();
     }
 }

 function initYTPlayer() {

     var tag = document.createElement('script');

     tag.id = 'YT_player_script';
     tag.src = "https://www.youtube.com/iframe_api";

     document.body.appendChild(tag);
 }

 /**
  * La video dans la fiche produit (champs produit_video) Charge une video à partir de Youtube
  */
 function show_video(vid) {

     var video = $('#videoBox');

     $(window).scrollTop(0);

     if (!YT_Player) {

         YT_vid_id = vid;

         if (!document.getElementById('YT_player_script')) {

             initYTPlayer();
         } else {

             onYouTubeIframeAPIReady();
         }
     }

     $('#shad').off('click', stop_video).after(video);
     $('#shad').stop(true, true).fadeIn('slow', function () {

         $('#shad').one('click', stop_video);
     });
 }

 function stop_video() {

     var video = $('#videoBox');

     if ( video.css('display') != 'none' ) {
         video.css({ display : 'block', opacity : 1 }).animate({ opacity : 0 }, function () {

             $('#shad').stop(true, true).fadeOut('slow', function () {

                 if (YT_Player !== undefined) {

                     YT_Player.seekTo(0).stopVideo();
                 }

                 video.css({ display : 'none' });
             });
         });
     }
 }

 if($('.pageGiftCard').length){
     $('.pageGiftCard__main--infos, .pageGiftCard__header .headerTitles, .showAmountBtn:not(.is-hidden)').addClass('is-shown');
 }

 function toggleGiftCardBalance() {
     $(".pageGiftCard__header .headerTitles, .pageGiftCard__main, .showAmountBtn").toggleClass('is-hidden is-shown');
 }

 /***** FUNCTION PUSH INTO DATALAYER  *****/
 function pushIntoDatalayer (data) {
     if (typeof(data) == 'object') {
         window.dataLayer.push(data);
     } else if (typeof(data) == 'string') {
         window.dataLayer.push(JSON.parse(data));
     }
 }

 /**** FAQ TUNNEL ****/
$(function() {
    // LAST THEME FAQ
    let elmThemeFaqLast = $('.cart .wrap_bottom_rightcol .bloc_question .faq_theme_wrapper:last-child');
    let email = elmThemeFaqLast.find('h3.intitule_question span').text();

    elmThemeFaqLast.find('.faq').empty();
    elmThemeFaqLast.find('.faq').append('<a href="mailto:' + email + '" class="intitule_question">' + email +'</a>');
    
});