// creer l'instanciation de l'objet XHR qu'inporte le navigateur
function getXMLHttpRequest() {
    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                "Msxml2.XMLHTTP.6.0",
                "Msxml2.XMLHTTP.3.0",
                "Msxml2.XMLHTTP",
                "Microsoft.XMLHTTP",
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                	xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch (error) {}
            }
        	} else {
            	xhr = new XMLHttpRequest();
        	}
      	} else {
    	return null;
    }
    return xhr;
}

// // Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
	if (window.XMLHttpRequest)
       // FIREFOX-
       xhr_object = new XMLHttpRequest();
   	else if (window.ActiveXObject)
       // IE
       xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
   	else return false;
   	xhr_object.open("GET", fichier, false);
   	xhr_object.send(null);
   	if (xhr_object.readyState == 4) return xhr_object.responseText;
   	else return false;
}

function ajax_file_post(url, params) {
   if (window.XMLHttpRequest)
       // FIREFOX-
       xhr_object = new XMLHttpRequest();
   else if (window.ActiveXObject)
       // IE
       xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

   xhr_object.open("POST", url, false);
   xhr_object.setRequestHeader(
       "Content-type",
       "application/x-www-form-urlencoded"
   );
   xhr_object.send(params);

   if (xhr_object.readyState == 4 && xhr_object.status == 200) {
       return xhr_object.responseText;
   }
}

function ajax_file_XML(fichier) {
	   if (window.XMLHttpRequest)
	       // FIREFOX-
	       xhr_object = new XMLHttpRequest();
	   else if (window.ActiveXObject)
	       // IE
	       xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
	   else return false;
	   xhr_object.open("GET", fichier, false);
	   xhr_object.send(null);
	   if (xhr_object.readyState == 4) return xhr_object.responseXML;
	   else return false;
}

var global_id_transporteur = '';
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;

    var divId = 'popup_numero_retour';
    divId += '_'+(multiTransp?idOrderTransp:idOrder);
    var formId = '#return_products_form';
    formId += '_'+(multiTransp?idOrderTransp:idOrder);

    $(formId).lionbars();
    
    openMultiShad(divId);
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp)
{
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour+'&choix=no_impression';
    } else {
        data = 'idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    }
    $.ajax({
        url : path_relative_root + 'ajax_create_etiquette.php',
        type : 'post',
        data : data,
        success : function (res) {
            if ( res.substr(0,5) == 'false' ) {
                alert(Translator.translate('js_error_return_label'));
            } else {
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp,idOrderTransp, numRetour) {
    var numRetour = "";
    if (typeof idOrderTransp != "undefined")
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp +
            "&idOrderTransp=" +
            idOrderTransp
        );
    else
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp
        );


    if (numRetour != "false") {

        var transporteur = '';
        // Dans le cas du multi transporteur on définie les bloc qui doive s'afficher
        if (multiTransp == 1) {
            transporteur = '_' + global_transporteur_id;
        }
        var commentaireRetourObjet = "";
        var divCreateNumRetourObjet = document.getElementById("createNumRetour" + transporteur);
        var divPrintPdfObjet = document.getElementById("printPdf" + transporteur);

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById(
                "commentaireRetourDiv"
            ).value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById(
                    "commentaireRetour"
                ).value;
            }
        }

        var recommand = $(".recommandation").length;

        var has_errors = false;
        $(".return_quantity").each(function() {
            var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
            return_choice.removeClass('error');

            if ($(this).val() > 0  && return_choice.val() == '0') {
                has_errors = true;
                return_choice.addClass('error');
            }
        });

        if (!has_errors) {
            if (recommand) {
                $(".recommandation p span").css("color", "#000");
                $('p[id^="liste_motif_retour"] .select').css("border", "none");
                $("#divBtnPrintPdf").show();
            }
            showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, false, idOrderTransp);
        } else {
            if (recommand) {
                $(".recommandation p span").css("color", "#a40000");
                $('p[id^="liste_motif_retour"] .select').css(
                    "border",
                    "2px solid #a40000"
                );
            }
        }

    }
}

function closeMultiShadPopUp(id) {
    $("#shad").off("click");

    // Hide modbox and shade and destroy shade's click event
    $("#" + id)
        .slideUp("slow")
        .queue(function() {
            if ($("#shad").is(":visible")) {
                $("#shad").fadeTo("slow", 0, function() {
                    $("#shad").css("display", "none");
                });
            }

            $(this).dequeue();
        });

    location.reload();
}

// --------------------------------------------------------------------------------

// Transformation XML en tableau
function traiteXmlQte(xmlDoc) {
	var options = xmlDoc.getElementsByTagName("option");
	var optionsListe = new Array();
	for (var i = 0; i < options.length; ++i) {
	   var optionsListe2 = new Array();

	   var id = options.item(i).getElementsByTagName("id");
	   var label = options.item(i).getElementsByTagName("label");
	   var color = options.item(i).getElementsByTagName("color");
	   optionsListe2.push(
	       id.item(0).firstChild.data,
	       label.item(0).firstChild.data,
	       color.item(0).firstChild.data
	   );
	   optionsListe.push(optionsListe2);
	}
	return optionsListe;
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                    parentPath +
                    'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length
                ? $("#" + couleurs_id).val()
                : "none";
            var tailles = $("#" + tailles_id).length
                ? $("#" + tailles_id).val()
                : "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            parentPath +
                "ajax_liste_qte.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs +
                "&idtaille=" +
                tailles +
                "&produit_lot_nb=" +
                $("#produit_lot_nb").val() +
                "&suff=" +
                (suff || "")
        );

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $("#qteProd");
            var regexp_epuise = /Stock /;
            var txt_stock = $("#qteProd>option:selected").text();

            if (
                select_stock.length > 0 &&
                txt_stock.match(regexp_epuise) != null
            ) {
                $("#add_alert").show();
                $("#add_basket").hide();
            } else {
                $("#add_alert").hide();
                $("#add_basket").show();
            }
        }
    }
}

function showQteListeGamme(elementID, idProduit, parentPath) {
    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById(
        "liste_qte_spe_" + idProduit
    );

    if (listQte || alternative_listQte) {
        if (listQte) {
            if (document.getElementById("couleurProd_" + idProduit))
                var couleurs = document.getElementById(
                    "couleurProd_" + idProduit
                ).value;
            if (document.getElementById("tailleProd_" + idProduit))
                var tailles = document.getElementById("tailleProd_" + idProduit)
                    .value;
            listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = "none";
            var tailles = "none";
            alternative_listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        var string_params = "";
        if ($("#produit_lot_nb_" + idProduit).val() != undefined)
            string_params +=
                "&produit_lot_nb=" + $("#produit_lot_nb_" + idProduit).val();

        if ($("#qte_default_" + idProduit).val() != undefined)
            string_params +=
                "&qte_default=" + $("#qte_default_" + idProduit).val();

        var response = ajax_file(
            parentPath +
                "ajax_liste_qte_alerte.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs +
                "&idtaille=" +
                tailles +
                string_params
        );

        if (response) {
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;
        }
    }
}

function showQteListeAssoc(elementID, idProduit, parentPath) {
    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById(
        "liste_qte_spe_" + idProduit
    );
    listQte.innerHTML =
        '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
        parentPath +
        'img/sablier.gif" />Chargement...</td></tr></table>';
    var couleurs = document.getElementById("couleurProdAssoc_" + idProduit);
    var tailles = document.getElementById("tailleProdAssoc_" + idProduit);
    if (listQte || alternative_listQte) {
        if (listQte) {
            var couleurs = document.getElementById("couleurProd_" + idProduit)
                .value;
            var tailles = document.getElementById("tailleProd_" + idProduit)
                .value;
            listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = "none";
            var tailles = "none";
            alternative_listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        //if (couleurs.value != "" && tailles.value != "") {
        var response = ajax_file(
            parentPath +
                "ajax_liste_qte_alerte.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs.value +
                "&idtaille=" +
                tailles.value
        );
        if (response) {
            // On ex�cute deux fois car replace ne remplace qu'une seule fois alors que nous avons deux occurences
            response = response.replace("qteProd_", "qteProdAssoc_");
            response = response.replace("qteProd_", "qteProdAssoc_");
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;
        }
        //}
        //else
    }
}

// Affiche la liste des auantites d'un produit (HTML Tag select)
function showQteListePanier(elementID, idProduit, i, paniervirtuelId) {
    var listQtePanier = document.getElementById(elementID);

    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    if (listQtePanier) {
        var response = ajax_file(
            "ajax/ajax_liste_qte_panier.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&i=" +
                i +
                "&panierId=" +
                paniervirtuelId
        );
        if (response) listQtePanier.innerHTML = response;
    }
}

function updatePanier(idProduit, i, paniervirtuelId) {
    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);
    var qte = document.getElementById("qteProd" + i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    ajax_file(
        "ajax/ajax_upd_panier.php?idprod=" +
            idProduit +
            "&idcouleur=" +
            idCouleur +
            "&idtaille=" +
            idTaille +
            "&qte=" +
            qte.value +
            "&panierId=" +
            paniervirtuelId
    );
}

function newUpdatePanier(
   	idProduit,
   	i,
   	paniervirtuelId,
   	prixU,
   	path_web,
   	titreObjet,
   	idElTaille,
   	lastTaille,
   	idElCouleur,
   	lastCouleur
 	) {
   if (i != "") {
       i = "_" + i;

       prixU = $("#prixU" + i).val();
   }

   var couleurs = $("#couleurProd" + i);
   var tailles = $("#tailleProd" + i);
   var res = "";
   var html_err = "";
   var flagError = false;

   if ($("#lightbox_achat_express").length > 0) {
       from_range = true;
   } else {
       from_range = false;
   }

   html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
   html_err +=
       '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

   if (tailles.length && tailles.val() == "") {
       $("#sizeerror").slideDown();

       return false;
   } else if (couleurs && couleurs.val() == "") {
       res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_couleur");
       flagError = true;
   } else if (
       document.getElementById("qteProd" + i) &&
       document.getElementById("qteProd" + i).value == ""
   ) {
       res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_quantity");
       flagError = true;
   }

   if (flagError) {
       alert(res);

       return false;
   } else {
       var qte = 0;

       if ($("#qteProd" + i)) {
           qte = $("#qteProd" + i).val();
       }

       var idCouleur = 0;
       var idTaille = 0;

       if (couleurs && couleurs.val() != "none") idCouleur = couleurs.val();

       if (tailles && tailles.val() != "none") idTaille = tailles.val();

       var array_response = [];
       var response = ajax_file(
           path_web +
               "ajax_upd_panier.php?prixu=" +
               prixU +
               "&idprod=" +
               idProduit +
               "&idcouleur=" +
               idCouleur +
               "&idtaille=" +
               idTaille +
               "&qte=" +
               qte +
               "&panierId=" +
               paniervirtuelId
       );

       if (response) {
           array_response = response.split("//");

           var designation = array_response[0];

           if (titreObjet.match("^[0-9]")) {
               if (designation == 1) {
                   designation += " lot de";
               } else {
                   designation += " lots de";
               }
           }

           designation += " " + titreObjet;

           if (document.getElementById(idElTaille)) {
               if (document.getElementById(idElTaille).selectedIndex) {
                   //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                   designation +=
                       " - Taille " +
                       document.getElementById(idElTaille).options[
                           document.getElementById(idElTaille).selectedIndex
                       ].text;
               } else if (lastTaille != "") {
                   designation += " - Taille " + lastTaille;
               }
           }

           if ($('[name="' + idElCouleur + '"]').length > 0) {
               if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                   designation +=
                       " - " +
                       $('[name="' + idElCouleur + '"]:checked')
                           .get(0)
                           .nextElementSibling.title.trim();
               } else if (lastCouleur != "") {
                   designation += " - " + lastCouleur;
               }
           }

           array_response[0] = designation;

           // SI il n'y a plus de stock
           if (array_response[1] == "false") {
               $("#size_" + idTaille).addClass("disabled");
               alert(translate("product_no_stock_2"));
               return false;
           }
       }

       return array_response;
   }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
      $(".loader_scroll").fadeTo("fast", 1);
    }

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {
        generateNewBlocSearch(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop);
    } else {

        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop);
    }
}

function paginate(page) {

    var form = document.getElementById('filter_sticky');
    
    if(form != null){
        var total_element = form.getValue('totalElems', parseFloat),
        nb_prod = form.getValue('nb_prod', parseFloat),
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $('.pager_wrapper'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat),
        see_all = form.getValue('see_all', parseFloat),
        page = page || 1;
    }
        

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {
            pagination.each(function () {
                if (see_all === 0 && nb_prod < total_element) {
                    this.innerHTML = '<div class="see_all_product" onclick="seeXProduct(32, ' + page + ')">' + translate('see_x_product', total_element) + '</div>' + res;
                } else if (total_element > nb_prod_default) {
                    this.innerHTML = '<div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ', 0)">' + translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                }
            });
        }
    });
}

function seeXProduct(nb_product, page) {
    //Pour afficher tout, on repart de la page courante
    if(nb_product === 32){
        //On force à 30 pour conserver l'organisation de la page
        nb_product = 30;
        $("#see_all").val("1");
        generateNewBlocProd((page -1));
        $("#initial_page").val(page);
    } else {
        $("#see_all").val("0");
        $("#nb_prod").val(nb_product);
        $("#initial_page").val(1);
        // Si on est sur la page de recherche
        if ($("#search_page").length > 0) {
            generateNewBlocSearch(0);
        } else {
            generateNewBlocProd(0);
        }
    }
}

/**
 * Pour charger tous les produits sur une page
 * @author
 * @since
 *
 * @param  String           type       [description]
 * @param  Number           idObj      [description]
 * @param  Number           page       [description]
 * @param  Number           nb_product [description]
 * @param  HTMLElement      btn        le bouton sur lequel on a cliqué pour charger les element (si undefined pas de probleme)
 *
 * @return void
 */

var bloc_prod_xhr;

function onColorCheckChange(evt) {
    if (this.checked && !evt.ctrlKey) {
        $(this.closest(".selected_options"))
            .find("input[type=checkbox]:checked")
            .not(this)
            .prop("checked", false);
    }
}

//Uilisée pour afficher les produits
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, isFrom) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat);

    
    //Ajout pour le scroll infini vers le haut
    if(fromScrollTop || fromScrollBottom){
        $('#is_loading').val('1');
    }

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky');


    // Si le prix existe
    if ($('input[name="price_min"]').length > 0) {
        // Récupération du prix
        output.prix = form.getValue('price_min', parseFloat) + '~' + form.getValue('price_max', parseFloat);
    }

    if (page === 'all') {

        /** LEGACY **/

        var hash;

        if ((hash = location.hash && location.hash.match(/i=[0-9]+/g)[0])) {

            var marginTop; 
            if($('.wrapper_bandeau')){
                margintop = $('.wrapper_bandeau').height() + 20
            } else {
                margintop = $('#site_head_wrap').height() + 20
            }
            var $elem = hash ? $('#i=' + hash.replace('=', '')) : $list_item,
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {

                $('#contents_wrapper').animate({
                    scrollTop: top
                }, 0.8);
            }, 0);
        }

        /** END LEGACY **/
    } else {

        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function (res) {
                var 
                    old_elm = document.getElementById('list_item'),
                    content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');
                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                // This block of code updates the SEO-related link elements in the HTML document.
                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if (content !== '') {

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".loader_scroll").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;
                    paginate(page + 1);

                    if (fromScrollTop) {
                        var firstMsg = $('#scroll_items .item:first');
                        var curOffset = firstMsg.offset().top - $(document).scrollTop();
                        old_elm.insertAdjacentHTML('afterbegin', content);
                        $(document).scrollTop(firstMsg.offset().top-curOffset);

                    } else {
                        old_elm.insertAdjacentHTML('beforeend', content);
                    }

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) {}
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) {}
                    }

                    /** END LEGACY **/

                }

                switchVisu();
                
            },
            complete: function () {
                //force trigger on input hidden to fire the change() event
                $('#totalElems').trigger('change'); 
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (!fromScrollBottom && !fromScrollTop) {
                    if($('#scroll_items .item').length <= 32){
                        if($('#see_all').val() == 1){
                            window.scrollTo(0, ($('#scroll_items').offset().top + ($('#scroll_items').height() /1.5)));
                        } else if($('.bloc_sub_categ').length > 0){
                            window.scrollTo(0, ($('.bloc_sub_categ').offset().top - 120));
                        } else {
                            window.scrollTo(0, ($('#scroll_items').offset().top - 120));
                        }  
                    } else {
                        //reset initialPage if from filter
                        $("#initial_page").trigger("change");
                    }  
                }

                if(fromScrollTop || fromScrollBottom){
                    $('#is_loading').val('0');
                }

                if(fromScrollBottom){
                    $("#initial_page").val(page +1);
                }
            }
        });
    }
}

function updateFilter(
    tab_response,
    page,
    parentPath,
    type,
    idObj,
    no_price_range_update
) {
    if (tab_response[0] != "") {
        var bloc_photo = tab_response[0];
        document.getElementById("list_item").innerHTML = bloc_photo;

        $(".slider_product_wrapper").each(function() {
            $(this).hover(
                function() {
                    $(this)
                        .children(".visu_product_slider")
                        .children(".visu_product_slider_hover")
                        .show();
                },
                function() {
                    $(this)
                        .children(".visu_product_slider")
                        .children(".visu_product_slider_hover")
                        .hide();
                }
            );
        });

        var nav = tab_response[2];
        var nav_bas = tab_response[3];

        var nb_prod = tab_response[4];

        if (document.getElementById("nb_prod_numeric"))
            document.getElementById("nb_prod_numeric").innerHTML = nb_prod;
        if (document.getElementById("label_nb_prod_numeric")) {
            if (nb_prod == "")
                document.getElementById("label_nb_prod_numeric").style.display =
                    "none";
            else
                document.getElementById("label_nb_prod_numeric").style.display =
                    "block";
        }

        if (typeof page == "undefined") {
            var prix_min = tab_response[5];
            var prix_max = tab_response[6];

            if (!no_price_range_update) {
                $("#amount1").val(prix_min);
                $("#amount2").val(prix_max);
                $("#slider-range").slider("option", {
                    min: parseInt(prix_min),
                    max: parseInt(prix_max),
                    values: [parseInt(prix_min), parseInt(prix_max)],
                });
                min_price = prix_min;
                max_price = prix_max;

                $(".ui-slider-range").width("100%");
                $(".ui-slider-range").css("left", "0%");
                $(".ui-slider-range")
                    .next()
                    .css("left", "0%");
                $(".ui-slider-range")
                    .next()
                    .next()
                    .css("left", "100%");
            }

            // couleur
            var color = tab_response[7];
            var tab_color_tmp = color.split("~");
            var cnt_color = tab_color_tmp.length;

            if (tab_color_tmp[0] != "") {
                txt_color = ajax_file(
                    parentPath + "ajax_sprintf.php?arg1=recherche_couleurs"
                );
                var bloc_color =
                    '<div class="select_recherche">' +
                    txt_color +
                    '</div><div class="selected_options select_colors"></div>';

                var str_color = "";

                for (var j = 0; j < cnt_color; j++) {
                    var detail_color = tab_color_tmp[j].split("|");
                    var class_color = "btn_select_off";

                    if (tab_color.length > 0) {
                        if (in_array_js(detail_color[0], tab_color)) {
                            class_color = "btn_select";
                        }
                    }
                    str_color +=
                        '<div id="couleur_' +
                        detail_color[0] +
                        '" class="left_menu_obj ' +
                        class_color +
                        " couleur_" +
                        detail_color[0] +
                        '"><div class="f_left couleur_check"></div><div class="f_left txt_menu">' +
                        detail_color[1] +
                         "</div></div>";
                }

                $("#bloc_couleur")
                    .show()
                    .find(".selected_options")
                    .html(str_color);
            } else {
                $("#bloc_couleur")
                    .hide()
                    .find(".selected_options")
                    .html("");
            }
            // size
            var size = tab_response[8];

            var tab_size_tmp = size.split("~");
            var cnt_size = tab_size_tmp.length;

            if (tab_size_tmp[0] != "") {
                txt_size = ajax_file(
                    parentPath + "ajax_sprintf.php?arg1=recherche_taille"
                );

                var bloc_size =
                    '<div class="select_recherche">' +
                    txt_size +
                    '</div><div class="selected_options"></div>';

                var str_size = "";

                for (var j = 0; j < cnt_size; j++) {
                    var detail_size = tab_size_tmp[j].split("|");
                    var class_size = "btn_select_off";

                    if (tab_taille.length > 0) {
                        if (in_array_js(detail_size[0], tab_taille))
                            class_size = "btn_select";
                    }

                    str_size +=
                        '<div id="taille_' +
                        detail_size[0] +
                        '" class="left_menu_obj ' +
                        class_size +
                        '"><div class="f_left"></div><div class="f_left txt_menu">' +
                        detail_size[1] +
                        "</div></div>";
                }

                $("#bloc_taille")
                    .show()
                    .find(".selected_options")
                    .html(str_size);
                var windowW = $(window).height();
                if (windowW >= 900) {
                    $("#choix_recherche").sideBarEase("refresh");
                }
            } else {
                $("#bloc_taille")
                    .hide()
                    .find(".selected_options")
                    .html("");
            }
        }
    } else {
        $("#list_item").html(
            ajax_file(parentPath + "ajax_sprintf.php?arg1=no_response_search")
        );
        $("#pagination_haut").html("");
        $("#pagination_bas").html("");
    }
    $(".left_menu_obj").on("click touchend", filterBoxesToggle);
    var windowW = $(window).height();
    if (windowW >= 900) {
        $("#choix_recherche").sideBarEase("refresh");
    }
    $("#page").val(0);
}

//On va regarde tout les bloc et on envoi l'ajax
//Pour les blocs de cases � cocher
//Pour le slider des prix
//Pour le bouton qui supprime un element
var bloc_search_xhr;

function generateNewBlocSearch(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById("filter_sticky"),
        output = {},
        is_from_page = false,
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        ctrl = Array.prototype.slice.call(form.elements, 0),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function(elm) {
        if (elm.type === "checkbox" || elm.type === "radio") {
            if (elm.checked && elm.value !== "") {
                if (output[elm.name] === undefined) {
                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === "hidden") {
            if (elm.value !== "") {
                output[elm.name] = elm.value;
            }
        }
    });

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join("~");
            }
        }
    }

    if (nb_product !== undefined) {
        form.nb_prod.value = nb_product;
    } else {
        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== "all") {
        form.page.value = page * nb_product;
        is_from_page = true;

    }

    // Add misc values to output object
    output.page = form.page.value;
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;
    output.keyword = form.keyword.value;
    output.nb_prod = form.nb_prod.value;
    output.nb_prod_default = form.nb_prod_default.value;
    output.totalElems = form.totalElems.value;

    // Si le prix existe
    if ($("#price_min").length > 0) {
        // Récupération du prix
        output.prix =
            form.getValue("price_min", parseFloat) +
            "~" +
            form.getValue("price_max", parseFloat);
    }

    if (bloc_search_xhr) {
        bloc_search_xhr.abort();
    }

    bloc_search_xhr = $.ajax({
        url: path_relative_root + "ajax_reload_search.php",
        type: "get",
        data: output,
        success: function(res) {
            var
                old_elm = document.getElementById("scroll_items"),
                content,
                itm_length;

            if (
                btn &&
                btn.nextElementSibling &&
                btn.nextElementSibling.classList.contains("btn_tunnel")
            ) {
                btn.nextElementSibling.style.display = "none";
            }

            res = res.split("><><");
            content = res[0].trim();

            size = res[8].trim();

            var array_size = size.split("~");

            itm_length = parseFloat(res[1]);

            if (content !== "") {

                if (!isNaN(page)) {
                    old_elm.setAttribute("data-p", page * nb_product);
                }

                old_elm.innerHTML = "";

                document.getElementById("nb_articles").innerHTML = itm_length;
                document.getElementById("totalElems").value = itm_length;

                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top-curOffset);

                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (window.lazyload !== undefined) {
                    lazyload.init();
                }

                /** LEGACY **/

                var js_compteur = $(".vf_countdown").length;
                var js_encart = content.match(
                    /\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g
                );

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(
                                js_compteur[i].replace(
                                    /(vf_compteur_begin|vf_compteur_end)/g,
                                    ""
                                )
                            );
                        }
                    } catch (e) {}
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(
                            /(\/\/vf_begin|\/\/vf_end)/g,
                            ""
                        );
                        eval(js_encart);
                    } catch (e) {}
                }

                /** END LEGACY **/
            }
        },
        complete: function() {
            //force trigger on input hidden to fire the change() event
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');
            if (!fromScrollBottom && !fromScrollTop) {
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: 0
                    }, 200);
                }, 250);
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
            }
        },
    });
}

//Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath, reload) {

 var str_year = '';
 var str_reload = '';
 var year = $("#year").val();

 if (year != '') {

     str_year = '&year=' + year;
 }

 if (reload !== undefined) {

     str_reload = '&reload=true';
 }

//Gestion de la surbrillance des onglets
var i = 1;
while (document.getElementById("onglet_" + i + "_mid")) {
     if (type == i) {
         $("#onglet_" + i + "_left").attr("class", "left-on");
         $("#onglet_" + i + "_mid").attr("class", "mid-on");
         $("#onglet_" + i + "_right").attr("class", "right-on");
     } else {
         $("#onglet_" + i + "_left").attr("class", "left-off");
         $("#onglet_" + i + "_mid").attr("class", "mid-off");
         $("#onglet_" + i + "_right").attr("class", "right-off");
     }
     i++;
}

 var container = document.getElementById(elementID);

 //clearTimeout(time_out);
 if (container) {
     var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);

     if (response) {
         if (reload !== undefined) {
             $('.content_commandes').html(response);
         } else {

             $(container).html(response);
         }
         return true;
     }
     else{
         container.innerHTML = '';
         return false;
     }
 }
 else
     return false;
}

/**
 * Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php)
 */
function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr("id"),
        mId = id.substring(id.indexOf("_") + 1),
        messageContainer = $(".message_account_container");

    if (messageContainer.hasClass("appear")) {
        messageContainer.removeClass("appear");
    } else {
        messageContainer.addClass("appear");
    }

    if (!elem.hasClass("actif")) {
        $(".message_detail").slideUp();
        $(".btn_ouvrir_message")
            .not(elem)
            .removeClass("actif");
        $(".btn_ouvrir_message")
            .not(elem)
            .text(msg_open);

        if ($("#msg_txt_" + mId).html() == "") {
            $.ajax({
                type: "post",
                data: {mId: mId, type: 1},
                url: path_relative_root + create_link("message_detail"),
                success: function(response) {
                    $("#msg_txt_" + mId).html(response);
                    $("#msg_" + mId).slideDown();
                },
            });
        } else $("#msg_" + mId).slideDown();
        elem.addClass("actif");
        elem.text(msg_close);
    } else {
        $(".message_detail").slideUp();
        elem.removeClass("actif");
        elem.text(msg_open);
    }
}

var timer;
// --------------------------------------------------------------------------------
function newNewsletterFromCmpid() {
    var flag = verif_mail("email_inscription_home", "login_email");

    if (flag) {
        flag = ajax_file(
            path_relative_root +
                "ajax_email_newsletter_exist.php?mail=" +
                document.getElementById("email_inscription_home").value
        );

        var elementID = "email_inscription_home";

        var objElement = document.getElementById(elementID);
        var objLabel = document.getElementById(elementID + "Label");
        var erreurText = document.getElementById("erreur_msg");

        if (flag === "1") {
            flag = false;
        } else {
            flag = true;
        }

        if (!flag) {
            erreurText.innerHTML =
                '<p class="title_toolltip">' +
                ajax_file(path + "ajax_sprintf.php?arg1=login_email") +
                " :</p>" +
                "Cet email est déjà inscrit à la newsletter";
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
        } else {
            objElement.className = "inputForm";
            objElement.style.border = "1px solid #CCCCCC";
            objLabel.className = "labelForm";
        }
    }

    if (flag) {
        clearTimeout(timer);

        document.getElementById("bloc_erreur").style.display = "none";
        document.getElementById("img_error_popup").style.display = "none";
    } else {
        var val = document.getElementById("erreur_msg").innerHTML;
        var elem_img = document.getElementById("img_error_popup");
        $("#img_error_popup").css("display", "inline-block");

        elem_img.setAttribute("onmouseover", function() {
            OverPopup(this, val, "visible", "cmpid_adwords");
        });
        elem_img.onmouseover = function() {
            OverPopup(this, val, "visible", "cmpid_adwords");
        };
        elem_img.setAttribute("onmouseout", function() {
            OverPopup(this, val, "none", "cmpid_adwords");
        });
        elem_img.onmouseout = function() {
            OverPopup(this, val, "none", "cmpid_adwords");
        };

        elem = document.getElementById("email_inscription_home");

        elem.style.border = "1px solid red";
        pos = getPos(elem_img);

        document.getElementById("bloc_erreur").style.position = "absolute";

        var nav = navigator.appVersion;

        if (!/*@cc_on!@*/ (false || !!document.documentMode)) {
            $("#bloc_erreur").fadeIn(400);
        } else {
            document.getElementById("bloc_erreur").style.display = "block";
        }

        var clone = $("#bloc_erreur").clone(),
            c_pos = {
                top:
                    $("#img_error_popup").offset().top -
                    $("#img_error_popup")
                        .offsetParent()
                        .offset().top -
                    $("#bloc_erreur").height() +
                    4,
                left:
                    $("#img_error_popup").offset().left -
                    $("#img_error_popup")
                        .offsetParent()
                        .offset().left -
                    $("#bloc_erreur").innerWidth() +
                    $("#img_error_popup").width() * 2 +
                    6,
            };

        $("#bloc_erreur").remove();
        $("#img_error_popup").after(clone);

        $("#bloc_erreur")
            .find("td:nth-child(2)")
            .children("img")
            .css({
                float: "right",
                margin: "0 7px",
            });

        $("#bloc_erreur").css({
            position: "absolute",
            top: c_pos.top,
            left: c_pos.left + 271,
            "z-index": "99999",
            opacity: "1",
        });

        window.clearTimeout(timer);

        timer = setTimeout("closeInfobulle('bloc_erreur')", 5000);
    }

    return flag;
}

 function ajaxDeconnect(path_relative_root) {
     var provenance = window.location.pathname,
         cpt_client = new RegExp(
             /(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/
         ),
         response;

     // Get the current page's name
     provenance = provenance.substr(
         provenance.lastIndexOf("/"),
         provenance.lastIndexOf(".")
     );

     // This actually disconnects the client
     response = ajax_file(path_relative_root + "ajax_deconnect.php");

     // If there was an error
     if (!response) alert("Erreur lors de la déconnexion.");
     // Else if the current page can't be displayed when not logged in: redirect
     else if (cpt_client.test(provenance))
         window.location.href =
             path_relative_root + create_link("connexion_login");
     // Else: refresh
     else window.location.reload();

     // If the page hasn't been reloaded: remove the loader and return false
     hideLoader();

     return false;
 }

 /** wishlist **/
 function addToWishlistShoppingcart() {
     $.post(path_relative_root + "ajax_cart_to_wishlist.php", function(resp) {
         $(".btn_top_wishlist .gris").html(
             "(x" + resp.result.wishlist_size + ")"
         );
         $("#addToWishlistShoppingcart")
             .removeClass("addToWishlistShoppingcart")
             .addClass("existsToWishlistShoppingcart");
         $("#addToWishlistShoppingcart").html(resp.result.button_label);
         $("#addToWishlistShoppingcart")
             .attr("onclick", "")
             .attr(
                 "href",
                 path_relative_root + create_link("client_index") + "#wl"
             );
     });
 }

 function deletePromoCode() {
     $.ajax({
         type: "get",
         url: path_relative_root + create_link("delete_promo_code"),
         success: function(response) {
             location.href = path_relative_root + create_link("order_kiala");
         },
     });
 }

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {
	$.ajax({
	 type: "post",
	 data: {
	     basket_id: basket_id,
	     prod_id: prod_id,
	     qte: qte,
	 },
	 url: path_relative_root + "ajax_removeBasketProd.php",
	 success: function(res) {
	     try {
	         res = $.parseJSON(res);
	     } catch (e) {
	         console.error("parseJSON");

	         return;
	     }

	     $("#cart_top").html(res.datas.html);
	     $('#show_top_cart').addClass('appear');

	     var count_bask = $('#cart_top .cart').attr('data-cart');

	 },
	});
}

function sendMessage(id) {
    //disable the default form submission

    var mId = id;
    $(".loader_reponse_contact").css("display", "block");
    $("#envoyer_rep").addClass("active");

    if ($("#msg_txt_" + id).val() != "") {
        var upload_fail = ajax_file(
            "ajax_sprintf.php?arg1=contact_msg_pj_fail"
        );
        var formData = new FormData($("form#data")[0]);
        $.ajax({
            url: path_relative_root + create_link("message_detail"),
            type: "POST",
            data: formData,
            async: false,
            cache: false,
            contentType: false,
            processData: false,
            success: function(response) {
                if (response == "1") {
                    $("#msg_conf_" + id).html(
                        ajax_file("ajax_sprintf.php?arg1=contact_msg_envoyer")
                    );
                    $("#msg_conf_" + id).show();
                    $("#msg_conf_" + id).addClass("active");
                    $("#msg_txt_" + id).val(" ");
                    clearInput("filepj1");
                    $(".loader_reponse_contact").css("display", "none");
                    $("#envoyer_rep").addClass("active");
                    return false;
                } else if (response == "3") {
                    clearInput("filepj1");
                } else {
                    $("#msg_conf_" + mId).html(
                        ajax_file("ajax_sprintf.php?arg1=contact_msg_fail")
                    );
                    $("#msg_conf_" + mId).show();
                }
            },
        });
    } else {
        $(".loader_reponse_contact").css("display", "none");
        $("#contact_msg_text_vide")
            .css("color", "red")
            .fadeIn();
        setTimeout(function() {
            formMessageReset(id);
        }, 5000);
        $("#msg_conf_" + mId).hide();
    }
}

/*
 * Achat express utiliser pour les lookbook (sur les tag)
 */
function ajax_achat_express(reference, element) {
    var arg = "";
    var productUrl = element.attr("data-url");

    $.ajax({
        type: "get",
        url:
            path_relative_root +
            "ajax_achat_express.php?ref=" +
            reference +
            arg,
        success: function(response) {
            var $lightbox = $("#lightbox_achat_express");

            $("#content_achat_express").html(response);

            openMultiShad("lightbox_achat_express");

            // lookbook lightbox color dropdown
            addSpanColor();
            colorDropdown();

            setTimeout(function() {
                checkSubmitState();
                showAddBasketError();
            }, 500);
        },
    });
}

function showAddBasketError() {
    $( ".prod_btn_wrapper .inactiv" )
    .mouseover(function() {
        $( "#sizeerror:not(.active_btn)" ).addClass('show');
    })
    .mouseout(function() {
        $( "#sizeerror" ).removeClass('show');
    });
}

function colorDropdown() {
    new SimpleBar($('.category .choices_list.choice_color')[0]);

    $('#ligne_couleur .drop_btn_big:not(".no_dropdown")').on('click', function (e) {
    e.preventDefault();
        $('#ligne_couleur .js-dropp-action').toggleClass('drop_button');
        $('.choices_list.choice_color').toggleClass('js-visible');
        $('.choices_list.size').removeClass('js-visible');
        $(this).toggleClass('opened');
        $('#ligne_pointure .js-dropp-action').removeClass('drop_button');
    });

    // Using as fake input select dropdown
    $('#ligne_couleur label').on('click', function() {
        $(this).addClass('js-visible').siblings().removeClass('js-visible');
        $('.choices_list.choice_color,#ligne_couleur .js-dropp-action').removeClass('js-visible');
        $('#ligne_couleur .drop_btn_big').removeClass('opened');
        $(this).parent().addClass('active').siblings().removeClass('active');
    });

    $('.choices_list.choice_color .input_check').on('click', function() {
        $('#ligne_couleur .dropp-header__btn').removeClass('drop_button');
        $('.choices_list.choice_color.js-visible').removeClass('js-visible');
        $('#ligne_couleur .drop_btn_big').removeClass('opened');
        $('.choices_list.choice_color').addClass('color_selected');
    });

    $('#ligne_couleur .dropp-header__btn').on('click', function() {
        $('.choices_list.choice_color.color_selected').removeClass('color_selected');
    });

    $('#ligne_couleur .prod_listes .choices_list .form_itm.color input:checked').parent('.form_itm').addClass('active');

}

function addSpanColor() {
    /**
     * retirer label style attribute et le mettre sur le span
     * afin de résoudre le problème de clique sur le label
     */
     var colorWrapper = $('body.category #lightbox_achat_express #ligne_couleur');

     colorWrapper.find('.form_itm.color').each(function() {
         var label = $( this ).find('label');
         
         if( label.length ) {
             var labelStyle = label.attr('style');
             var span = $('<span />').attr('style', labelStyle);
 
             label.removeAttr("style");
             label.prepend(span);
         }
     });
}

function switchVisu() {

    var src_format = $('#list_item').length && $('#list_item').hasClass('bydefault')? 'prodfp':'medium';
    var dest_format = $('#list_item').length && $('#list_item').hasClass('bydefault')? 'medium':'prodfp';

    $('.swiper-lazy').each(function (){
        if(typeof($(this).attr('data-src'))!='undefined') {
            $(this).attr('data-src',$(this).attr('data-src').replace(src_format,dest_format));
        }
        if(typeof($(this).attr('src'))!='undefined') {
            $(this).attr('src',$(this).attr('src').replace(src_format,dest_format));
        }
    });
}

function viewByFour(){
    if($('#list_item').length && $('#list_item').hasClass('bydefault')){
        $('#list_item').removeClass('bydefault');
        $('#list_item').addClass('byFour');
        $('#viewChanger .byFour').addClass('actif');
        $('#viewChanger .default').removeClass('actif');
        switchVisu();
        createCookie("nb_produits", 4, 1);
    }

    setTimeout(function () {
        $('.productSwiper.swiper-container').each(function () {
            if($(this).hasClass('swiper-container-initialized')){
                this.swiper.update();
            }
        });
    }, 400);
}

function viewDefault(){
    if($('#list_item').length && $('#list_item').hasClass('byFour')){
        $('#list_item').removeClass('byFour');
        $('#list_item').addClass('bydefault');
        $('#viewChanger .byFour').removeClass('actif');
        $('#viewChanger .default').addClass('actif');    
        switchVisu();   
        createCookie("nb_produits", 2, 1);
    }

    setTimeout(function () {
        $('.productSwiper.swiper-container').each(function () {
            if($(this).hasClass('swiper-container-initialized')){
                this.swiper.update();
            }
        });
    }, 400);
}

function filterTrigger(){
    if($('#rayon_sticky_block').length){
        var trigger = $('#trigger_filtre');
        var filter = $('#rayon_sticky_block');
        var shad = $('#shad_link');
        var hHeader = $('#site_head_wrap').height();

        if(!trigger.hasClass('triggered')){
            trigger.addClass('triggered');
            filter.addClass('show');
            shad.addClass('actif filterOn');

            $("html, body").animate({
                scrollTop: $('#site_head_wrap').offset().top,
            }, 500);

        } else if(trigger.hasClass('triggered')){
            closeFiltre();
        }

        if(trigger.hasClass('triggered')){
            var shad_filtre = $('#shad_link.filterOn');
            shad_filtre.on("click touch", function(){
                closeFiltre();
            });
        }

        function closeFiltre(){
            if(trigger.hasClass('triggered')){
                trigger.removeClass('triggered');
                filter.removeClass('show');
                shad.removeClass('actif filterOn');
            }
            //APPEND ADDWISHLIST BTN TO PLACE IT CORRECTLY
            setTimeout(function(){
                //APPEND ADDWISHLIST BTN TO PLACE IT CORRECTLY
                if($('.category .wishlistBtn.addToWishlistButton').length) {
                    $('#list_item .item .wishlistBtn.addToWishlistButton').each(function() {
                        $(this).appendTo($(this).closest('.item'));
                        $(this).css('opacity', '1');
                    });
                }
            }, 150);
        }
    }

    //APPEND ADDWISHLIST BTN TO PLACE IT CORRECTLY
    setTimeout(function(){
        //APPEND ADDWISHLIST BTN TO PLACE IT CORRECTLY
        if($('.category .wishlistBtn.addToWishlistButton').length) {
            $('#list_item .item .wishlistBtn.addToWishlistButton').each(function() {
                $(this).appendTo($(this).closest('.item'));
                $(this).css('opacity', '1');
            });
        }
    }, 150);
}

var tempo_reset = false;

function initAllFilters() {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.value = 0;
                elm.checked = false;
            }
        }
    });

    $('a.tri_price.actif').removeClass('actif');
    $('input#choice_tri').val(0);

    tempo_reset = true;
    generateNewBlocProd.call(form, 0);

    //APPEND ADDWISHLIST BTN TO PLACE IT CORRECTLY
    setTimeout(function(){
        //APPEND ADDWISHLIST BTN TO PLACE IT CORRECTLY
        if($('.category .wishlistBtn.addToWishlistButton').length) {
            $('#list_item .item .wishlistBtn.addToWishlistButton').each(function() {
                $(this).appendTo($(this).closest('.item'));
                $(this).css('opacity', '1');
            });
        }
    }, 150);
}

function readMore(){
    var txt = $('.txt_bandeau .txt');
    var txtH = $('.txt_bandeau .txt')[0].scrollHeight;
    var showTxt = $('.txt_bandeau .txt_trigger');

    if(!showTxt.hasClass('triggered')){
        showTxt.addClass('triggered');
        txt.css('height', txtH + 'px');
        showTxt.text(translate('see_less'));
    } else {
        showTxt.removeClass('triggered');
        showTxt.text(translate('read_more'));
        if(txtH > 105){
            txt.css('height', '105px');
        } else {
            txt.css('height', txtH + 'px');
            showTxt.hide();
        }
    }
}

$(document).on("click","#btn_top_add_wishlist", function (e) {
    e.preventDefault();
    selectedProducts = [];
    $(".remove_top_wishlist").each(function( index ){
        var id = $(this).attr("data-id");
        selectedProducts.push(id);
        
    });
    moveProductFromWishlistToCart(selectedProducts);
});

function moveProductFromWishlistToCart(array_wishlist_product_ids) {
    $.post(path_relative_root + "ajax_add_panier_wishlist.php", {data: array_wishlist_product_ids.join(",")}, function(resp) {
        if (resp == "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}